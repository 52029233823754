import Splide from '@splidejs/splide';

export const carousels = ($) => {

  const carouselBaseConfig = {
    type: 'slide',
    drag: true,
    arrows: false,
    pagination: false
  };

  const draggingClass = 'js-carousel-dragging';

  const enableCarousels = (className, additionalConfig, hideCursorTextOnDrag = false) => {
    const $carousels = $(`.${className}`);

    if ($carousels.length == 0) return;

    $carousels.each((index, el) => {
      const $el = $(el);
      const uniqueClass = `${className}-${index}`;

      // Add unique class
      $el.addClass(uniqueClass);

      const slider = new Splide(`.${uniqueClass}`, {
        ...carouselBaseConfig,
        ...additionalConfig,
      });

      if (hideCursorTextOnDrag) {
        slider.on('dragging', () => {
          $el.addClass(draggingClass);
        });
        slider.on('dragged', () => {
          $el.removeClass(draggingClass);
        });
      }

      slider.on('mounted', () => {
        let $slides = $el.find('.splide__slide');
        let $slideList = $el.find('.splide__list');

        // Modify the slides
        $slides.each((index, el) => {
          const $slide = $(el);
          $slide.removeAttr('role');
          $slide.attr('role', 'listitem');
        });

        // Modify the slide list
        if ($slideList) {
          $slideList.removeAttr('role');
          $slideList.attr('role', 'list');
        }
      });

      slider.mount();
    });
  };

  const enableBigTileCarousels = () => {
    enableCarousels('js-carousel-big-tile', {
      perPage: 2,
      gap: 40,
      breakpoints: {
        991: {
          perPage: 1,
          gap: 50,
          padding: { right: '15%', left: '50px' },
        },
        574: {
          gap: 20,
          padding: { right: '15%', left: '20px' },
        },
      },
    }, true);
  };

  const enableClientsCarousels = () => {
    enableCarousels('js-carousel-clients', {
      perPage: 3,
      gap: 35,
      padding: { right: '15%' },
      breakpoints: {
        1300: {
          perPage: 2,
        },
        768: {
          perPage: 1,
        },
      },
    });
  };


  const cursorColours = {
    blue: '#0061FE',
    green: '#00D188',
    pink: '#FF3A6F',
  };

  const randomColour = (exclude) => {
    const values = Object.values(cursorColours);
    const filteredValues = values.filter((e) => { return e !== exclude })
    return filteredValues[(Math.floor(Math.random() * filteredValues.length))];
  };

  const changeCursorColour = (cursor, cursorState) => {
    cursorState.colour = randomColour(cursorState.colour);
    cursor.style.backgroundColor = cursorState.colour;
  };

  const enableCursorText = () => {
    const withCursors = document.querySelectorAll(".js-has-cursor-text");

    if (withCursors.length == 0) return;

    withCursors.forEach((withCursor) => {
      const cursor = withCursor.querySelector(".js-cursor-text");

      if (!cursor) return;

      const cursorState = {
        colour: cursorColours.blue,
      };

      const updateCursorColour = (e) => {
        changeCursorColour(cursor, cursorState);
      };

      const moveCursor = (e) => {
        const hoveredHideElement = document.querySelectorAll('.js-cursor-text-hidden-here:hover');

        if (hoveredHideElement.length > 0) {
          cursor.style.opacity = 0;
        } else {
          cursor.style.opacity = 1;
          const x = e.clientX;
          const y = e.clientY;
          cursor.style.left = x + "px";
          cursor.style.top = y + "px";
        }
      };

      const hideCursor = (e) => {
        cursor.style.opacity = null;
      };

      withCursor.addEventListener("mouseenter", updateCursorColour);
      withCursor.addEventListener("mousemove", moveCursor);
      withCursor.addEventListener("mouseleave", hideCursor);
    });
  };


  const enableClientsCards = () => {
    enableClientsCarousels();

    const cardsClass = '.js-carousel-clients-card';
    const slideClass = '.js-slide';

    const $cards = $(cardsClass);

    if ($cards.length == 0) return;

    $cards.on('touch click', (e) => {
      const $target = $(e.target);
      const $slide = $target.closest(slideClass);
      const $list = $target.closest('.js-list');
      const label = $slide.attr('aria-label');

      // get originals and all clones and toggle
      $list.find(slideClass + '[aria-label="' + label + '"] ' + cardsClass)
        .toggleClass('active');
    })
  };

  // run em all
  enableBigTileCarousels();
  enableCursorText();
  // also enables the client carousels
  enableClientsCards();
};
