// List of UTM parameters and unique codes to store
export const trackingParameters = [
  'utm_source', 'utm_source_platform', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term',
  'gclid', 'msclkid', 'li_fat_id'
];

// Function to store a parameter in local storage if it exists
export const storeParameter = (paramName, paramValue) => {
    if (paramValue) {
        localStorage.setItem(paramName, paramValue);
    }
};

// Function to get a parameter in local storage if it exists
export const getParameter = (paramName) => {
  return localStorage.getItem(paramName) || '';
};

// Function to retrieve tracking codes from local storage
export const getTrackingCodes = () => {
    const trackingCodes = {};
    trackingParameters.forEach(paramName => {
        const paramValue = localStorage.getItem(paramName);
        if (paramValue) {
            trackingCodes[paramName] = paramValue;
        }
    });

    return trackingCodes;
};
