import "bootstrap/js/dist/collapse";
import { revealAnimations } from "./includes/revealAnimations.js";
import { hamburgerMenu } from "./includes/hamburgerMenu.js";
import { unmuteAutoplayVideo } from "./includes/unmuteAutoplayVideo.js";
import { heroBanner } from "./includes/heroBanner.js";
import { backgroundColorTransition } from "./includes/backgroundColorTransition.js";
import { infoPanels } from "./includes/infoPanels.js";
import { formsExtra } from "./includes/formsExtra.js";
import { stickyHeader } from "./includes/stickyHeader.js";
import { shaveTileText } from "./includes/shaveTileText.js";
import { smoothAnchorScrollsOnPage } from "./includes/smoothAnchorScrollsOnPage.js";
import { colourShiftTransitions } from "./includes/colourShiftTransitions.js";
import { carousels } from "./includes/carousels.js";
import { peopleStack } from "./includes/peopleStack.js";
import { officeClock } from "./includes/officeClock.js";
import { officeMap } from "./includes/officeMap.js";
import { showreel } from "./includes/showreel.js";
import { lazyLoadVideos } from "./includes/lazyLoadVideos.js";
import { hubspotContactForm } from "./includes/hubspotContactForm.js";
import { storeTrackingCodes } from "./includes/storeTrackingCodes.js";
jQuery.noConflict();

(function ($) {
  $(document).ready(function () {
    hamburgerMenu($);

    revealAnimations($);

    unmuteAutoplayVideo($);

    heroBanner($);

    backgroundColorTransition($);

    infoPanels($);

    formsExtra($);

    stickyHeader($);

    shaveTileText($);

    smoothAnchorScrollsOnPage($);

    colourShiftTransitions($);

    carousels($);

    peopleStack($);

    officeClock($);

    officeMap($);

    showreel($);

    lazyLoadVideos($);

    hubspotContactForm($);

    storeTrackingCodes($);

    // Trigger the scroll event to initialise the page.
    $(window).scroll();
  });
})(jQuery);
