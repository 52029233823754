import { storeParameter, trackingParameters } from "../../shared/trackingCodes";
import queryString from 'query-string';

export const storeTrackingCodes = ($) => {
    // Parse the URL query string
    const parsed = queryString.parse(window.location.search);

    // Store each tracking parameter in local storage
    trackingParameters.forEach(paramName => {
        storeParameter(paramName, parsed[paramName]);
    });
};
